import * as React from "react";
import "./AddressInput.css";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import Autosuggest from "react-autosuggest";
import "../../../../shared-css/rk-btn.css";
import { IGeolocatedAddress } from "../../../../interfaces/IGeolocatedAddress";

export interface IAddressInputProps {
  geolocatedAddressAvailable(address: IGeolocatedAddress): void;
}

interface IAddressInputState {
  countrySuggestions: ICountry[];
  localitiesSuggestions: IExpandedLocality[];
  streetSuggestions: IStreet[];
  houseNumberSuggestions: IAddress[];

  country: string;
  postalCode: string;
  cityName: string;
  streetName: string;
  houseNumber: string;

  isLoading: boolean;
  showEnterCorrectLocationText: boolean;
  showErrorText: boolean;
}

interface IExpandedLocality {
  postalCode: string;
  communeName: string;
  localityName: string;
}

export default class AddressInput extends React.Component<
  IAddressInputProps,
  IAddressInputState
> {
  private ADDRESS_SEARCH_BASE_URL = process.env.REACT_APP_ADDRESS_SEARCH_URL;

  constructor(props: IAddressInputProps) {
    super(props);

    this.state = {
      countrySuggestions: [],
      localitiesSuggestions: [],
      streetSuggestions: [],
      houseNumberSuggestions: [],

      country: "AUT",
      postalCode: "",
      cityName: "",
      streetName: "",
      houseNumber: "",

      isLoading: false,
      showEnterCorrectLocationText: false,
      showErrorText: false,
    };
  }

  private getCountrySuggestions(value: string): void {
    if (!value) {
      return;
    }

    fetch(this.ADDRESS_SEARCH_BASE_URL + "/Countries?name=" + value)
      .then((res) => (res.ok ? res.json() : []))
      .then((output: ICountry[]) => {
        this.setState({
          countrySuggestions: output.slice(0, 5),
        });
      });
  }

  private getPostalCodeSuggestions(value: string): void {
    this.getLocalitiesSuggestions(value, this.state.cityName);
  }

  private getCityNameSuggestions(value: string): void {
    this.getLocalitiesSuggestions(this.state.postalCode, value);
  }

  private getLocalitiesSuggestions(postalCode: string, cityName: string): void {
    if (this.state.country !== "AUT" || (!postalCode && !cityName)) {
      return;
    }

    let url = this.ADDRESS_SEARCH_BASE_URL + "/Localities";

    let queryParam = "";

    if (postalCode) {
      queryParam += "&postalCode=" + postalCode;
    }

    if (this.state.cityName) {
      queryParam += "&name=" + cityName;
    }

    url += "?" + queryParam.substring(1);

    fetch(url)
      .then((res) => (res.ok ? res.json() : []))
      .then((output: ILocality[]) => {
        const suggestions: IExpandedLocality[] = [];

        for (let i = 0; i < output.slice(0, 5).length; i++) {
          suggestions.push(...this.generateLocalitySuggestions(output[i]));
        }

        this.setState({
          localitiesSuggestions: suggestions,
        });
      });
  }

  private generateLocalitySuggestions(
    locality: ILocality
  ): IExpandedLocality[] {
    const suggestions: IExpandedLocality[] = [];

    if (this.state.postalCode) {
      suggestions.push({
        communeName: locality.commune.name,
        localityName: locality.name,
        postalCode: this.state.postalCode,
      });
    } else {
      locality.postalCodes.forEach((x) => {
        suggestions.push({
          communeName: locality.commune.name,
          localityName: locality.name,
          postalCode: x.toString(),
        });
      });
    }

    return suggestions;
  }

  private getStreetSuggestions(value: string): void {
    if (
      this.state.country !== "AUT" ||
      !this.state.postalCode ||
      !this.state.cityName ||
      !value
    ) {
      return;
    }

    let queryParam =
      "?postalCode=" +
      this.state.postalCode +
      "&communeName=" +
      this.state.cityName +
      "&name=" +
      value;

    fetch(this.ADDRESS_SEARCH_BASE_URL + "/Streets" + queryParam)
      .then((res) => (res.ok ? res.json() : []))
      .then((output: IStreet[]) => {
        this.setState({
          streetSuggestions: output.slice(0, 5),
        });
      });
  }

  private getHouseNumberSuggestions(value: string): void {
    if (
      this.state.country !== "AUT" ||
      !this.state.postalCode ||
      !this.state.cityName ||
      !this.state.streetName ||
      !value
    ) {
      return;
    }

    let queryParam =
      "?postalCode=" +
      this.state.postalCode +
      "&communeName=" +
      this.state.cityName +
      "&streetName=" +
      this.state.streetName +
      "&houseNumber=" +
      value;

    fetch(this.ADDRESS_SEARCH_BASE_URL + "/Addresses" + queryParam)
      .then((res) => (res.ok ? res.json() : []))
      .then((output: IAddress[]) => {
        this.setState({
          houseNumberSuggestions: output.slice(0, 5),
        });
      });
  }

  private prepareCountryItem(country: ICountry) {
    return (
      <span>
        {country.shortForm} - {country.name}
      </span>
    );
  }

  private prepareLocalityItem(locality: IExpandedLocality) {
    let displayName = locality.postalCode + " " + locality.communeName;

    if (locality.localityName !== locality.communeName) {
      displayName += " (" + locality.localityName + ")";
    }

    return displayName;
  }

  private prepareStreetItem(street: IStreet) {
    return <span>{street.name}</span>;
  }

  private prepareHouserNumberItem(address: IAddress) {
    return <span>{address.houseNumber}</span>;
  }

  private getCountryValue(country: ICountry) {
    return country.shortForm;
  }

  private getLocalityValue(
    locality: IExpandedLocality,
    isPostalCode: boolean
  ): string {
    if (isPostalCode) {
      this.setState({
        cityName: locality.communeName,
      });

      return locality.postalCode;
    } else {
      this.setState({
        postalCode: locality.postalCode,
      });

      return locality.communeName;
    }
  }

  private getStreetValue(street: IStreet) {
    return street.name;
  }

  private getHouseNumberValue(address: IAddress) {
    return address.houseNumber;
  }

  private getInputComponent(inputProps: any) {
    return (
      <div className="floating-label-container">
        <input {...inputProps} />
        <label className="react-autosuggest__label">
          {inputProps.placeholder}
        </label>
      </div>
    );
  }

  private continueClicked() {
    this.setState({
      showErrorText: false,
      showEnterCorrectLocationText: false,
      isLoading: true,
    });

    const queryParam =
      "?postalCode=" +
      this.state.postalCode +
      "&communeName=" +
      this.state.cityName +
      "&streetName=" +
      this.state.streetName +
      "&houseNumber=" +
      this.state.houseNumber;

    fetch(this.ADDRESS_SEARCH_BASE_URL + "/Addresses" + queryParam)
      .then((res) => res.json())
      .then((output: IAddress[]) => {
        if (output.length > 0) {
          const matchingItem = output[0];

          if (this.state.houseNumber.trim() === matchingItem.houseNumber) {
            const geolocatedAddress: IGeolocatedAddress = {
              postalCode: this.state.postalCode,
              city: this.state.cityName,
              street: this.state.streetName,
              houseNumber: this.state.houseNumber,
              localityId: matchingItem.localityId,
              latitude: matchingItem.latitude,
              longitude: matchingItem.longitude,
            };

            this.setState({
              postalCode: "",
              cityName: "",
              streetName: "",
              houseNumber: "",
            });

            setTimeout(() => {
              this.props.geolocatedAddressAvailable(geolocatedAddress);
            }, 250);

            return;
          }
        }

        this.setState({
          showEnterCorrectLocationText: true,
        });
      })
      .catch(() => {
        this.setState({ showErrorText: true });
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  private getMessages() {
    if (this.state.showErrorText) {
      return (
        <Alert variant="danger">
          Es ist ein Fehler beim Abrufen der Adressinformationen aufgetreten.
        </Alert>
      );
    } else if (this.state.showEnterCorrectLocationText) {
      return (
        <Alert variant="warning">
          Bitte wählen Sie eine gültige Adresse mithilfe der Adressvorschläge
          aus. Die eingegebene Adresse konnte nicht gefunden werden.
        </Alert>
      );
    }
  }

  public render() {
    const countryInputProps = {
      placeholder: "Land",
      value: this.state.country,
      onChange: (_: any, { newValue }: any) => {
        this.setState({ country: newValue });
      },
      className: "me-1 react-autosuggest__input full-width",
      spellCheck: false,
      disabled: true,
    };

    const postalCodeInputProps = {
      placeholder: "PLZ",
      value: this.state.postalCode,
      onChange: (_: any, { newValue }: any) => {
        this.setState({ postalCode: newValue });
      },
      className: " ms-1 react-autosuggest__input full-width",
      spellCheck: false,
    };

    const cityInputProps = {
      placeholder: "Stadt/Ort",
      value: this.state.cityName,
      onChange: (_: any, { newValue }: any) => {
        this.setState({ cityName: newValue });
      },
      className: "react-autosuggest__input full-width",
      spellCheck: false,
    };

    const streetInputProps = {
      placeholder: "Straße",
      value: this.state.streetName,
      onChange: (_: any, { newValue }: any) => {
        this.setState({ streetName: newValue });
      },
      className: "me-1 react-autosuggest__input full-width",
      spellCheck: false,
    };

    const houseNumberInputProps = {
      placeholder: "HNr.",
      value: this.state.houseNumber,
      onChange: (_: any, { newValue }: any) => {
        this.setState({ houseNumber: newValue });
      },
      className: "ms-1 react-autosuggest__input full-width",
      spellCheck: false,
    };

    const continueAvailable: boolean =
      this.state.country &&
      this.state.postalCode &&
      this.state.cityName &&
      this.state.houseNumber &&
      !this.state.isLoading
        ? true
        : false;

    return (
      <div className="mx-3">
        <Form autoComplete="off">
          <div className="d-flex">
            <div className="flex-fill">
              <Autosuggest
                inputProps={countryInputProps}
                suggestions={this.state.countrySuggestions}
                onSuggestionsFetchRequested={({ value }: any) =>
                  this.getCountrySuggestions(value as string)
                }
                onSuggestionsClearRequested={() =>
                  this.setState({ countrySuggestions: [] })
                }
                getSuggestionValue={this.getCountryValue}
                renderSuggestion={this.prepareCountryItem}
                renderInputComponent={this.getInputComponent}
              />
            </div>

            <div className="flex-fill">
              <Autosuggest
                inputProps={postalCodeInputProps}
                suggestions={this.state.localitiesSuggestions}
                onSuggestionsFetchRequested={({ value }: any) =>
                  this.getPostalCodeSuggestions(value as string)
                }
                onSuggestionsClearRequested={() =>
                  this.setState({ localitiesSuggestions: [] })
                }
                getSuggestionValue={(value) =>
                  this.getLocalityValue(value, true)
                }
                renderSuggestion={this.prepareLocalityItem}
                renderInputComponent={this.getInputComponent}
              />
            </div>
          </div>

          <div>
            <Autosuggest
              inputProps={cityInputProps}
              suggestions={this.state.localitiesSuggestions}
              onSuggestionsFetchRequested={({ value }: any) =>
                this.getCityNameSuggestions(value as string)
              }
              onSuggestionsClearRequested={() =>
                this.setState({ localitiesSuggestions: [] })
              }
              getSuggestionValue={(value) =>
                this.getLocalityValue(value, false)
              }
              renderSuggestion={this.prepareLocalityItem}
              renderInputComponent={this.getInputComponent}
            />
          </div>

          <div className="d-flex">
            <div className="flex-fill three-quarter-width">
              <Autosuggest
                inputProps={streetInputProps}
                suggestions={this.state.streetSuggestions}
                onSuggestionsFetchRequested={({ value }: any) =>
                  this.getStreetSuggestions(value as string)
                }
                onSuggestionsClearRequested={() =>
                  this.setState({ streetSuggestions: [] })
                }
                getSuggestionValue={this.getStreetValue}
                renderSuggestion={this.prepareStreetItem}
                renderInputComponent={this.getInputComponent}
              />
            </div>

            <div className="flex-fill">
              <Autosuggest
                inputProps={houseNumberInputProps}
                suggestions={this.state.houseNumberSuggestions}
                onSuggestionsFetchRequested={({ value }: any) =>
                  this.getHouseNumberSuggestions(value as string)
                }
                onSuggestionsClearRequested={() =>
                  this.setState({ houseNumberSuggestions: [] })
                }
                getSuggestionValue={this.getHouseNumberValue}
                renderSuggestion={this.prepareHouserNumberItem}
                renderInputComponent={this.getInputComponent}
              />
            </div>
          </div>

          <div>{this.getMessages()}</div>

          <div>
            <Button
              disabled={!continueAvailable}
              variant="rk-red"
              className="full-width mt-2"
              onClick={() => this.continueClicked()}
            >
              {this.state.isLoading ? <Spinner animation="border" /> : "Weiter"}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

interface ICountry {
  id: number;
  shortForm: string;
  name: string;
}

interface ILocality {
  id: number;
  name: string;
  postalCodes: number[];
  commune: ICommune;
}

interface ICommune {
  id: number;
  name: string;
}

interface IStreet {
  id: number;
  name: string;
  commune: ICommune;
}

interface IAddress {
  id: number;
  houseNumber: string;
  street: IStreet;
  localityId: number;
  latitude: number;
  longitude: number;
}
