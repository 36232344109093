export abstract class DistrictProvider {
  public static getSpecifiedDistrict(): string | undefined {
    const params = new URLSearchParams(window.location.search);

    if (params.has('bezirk')) {
      return params.get('bezirk') ?? undefined;
    }

    return undefined;
  }
}
