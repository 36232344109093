import { IConfigurationDataRaw } from "../interfaces/ICostRawData";

export class CostCalculator {
  private priceTable: Record<string, Record<string, ICostZoneItem[]>>;

  constructor(data: IConfigurationDataRaw) {
    this.priceTable = {};

    this.parseZones(data);
  }

  static fromJson(json: string) {
    const parsedJson = JSON.parse(json);
    return new CostCalculator(parsedJson);
  }

  private parseZones(data: IConfigurationDataRaw) {
    Object.keys(data.priceTable).forEach((district) => {
      const zonesPriceTable: Record<string, ICostZoneItem[]> = {};

      Object.keys(data.priceTable[district]).forEach((zone) => {
        const zonePrices: ICostZoneItem[] = data.priceTable[district][zone];

        zonesPriceTable[zone] = zonePrices;
      });

      this.priceTable[district] = zonesPriceTable;
    });
  }

  public getCostsForKilometers(
    kilometer: number,
    zoneIdentifier: string,
    district?: string,
    locationHint?: string
  ): string | null {
    let adjustZone = "default";

    if (district && this.priceTable[district]) {
      adjustZone = district!;
    }

    const zonePrices = this.priceTable[adjustZone][zoneIdentifier];

    kilometer = Math.round(kilometer);

    let filteredZones: ICostZoneItem[] = [];

    if (locationHint) {
      filteredZones = zonePrices.filter(
        (x) =>
          kilometer >= x.fromKilometer &&
          kilometer <= x.toKilometer &&
          x.locationHintFilter === locationHint
      );
    } else {
      filteredZones = zonePrices.filter(
        (x) =>
          kilometer >= x.fromKilometer &&
          kilometer <= x.toKilometer &&
          !x.locationHintFilter
      );
    }

    if (filteredZones.length === 1) {
      const usedZone = filteredZones[0];

      let result = usedZone.basePrice;

      if (usedZone.kilometerPrice) {
        let kilometerPriceStart = usedZone.kilometerPriceStart;

        if (!kilometerPriceStart) {
          kilometerPriceStart = 0;
        }

        result += (kilometer - kilometerPriceStart) * usedZone.kilometerPrice;
      }

      return result.toFixed(2);
    }

    return null;
  }
}

// JSON RAW CLASSES

interface ICostZoneItem {
  fromKilometer: number;
  toKilometer: number;
  basePrice: number;
  kilometerPrice?: number;
  kilometerPriceStart?: number;
  locationHintFilter?: string;
}
