import SingleAnswer from '../components/interaction-items/single-answer/SingleAnswer';
import { IAnswer } from '../interfaces/IAnswer';
import { IConfigurationDataRaw } from "../interfaces/ICostRawData";
import { IHelp } from '../interfaces/IHelp';
import { IInteractionItemProps } from '../interfaces/IInteractionItemProps';
import { IStringAnswer } from '../interfaces/IStringAnswer';
import { FlowAnswer } from './FlowAnswer';
import { FlowHelp } from './FlowHelp';
import { FlowItem } from './FlowItem';
import { FlowNextItemConfig } from './FlowNextItemConfig';

export class FlowSingleAnswer extends FlowItem {
  constructor(
    id: number,
    name: string,
    heading: string,
    question: string,
    answers: FlowAnswer<string>[],
    isStart?: boolean,
    help?: FlowHelp
  ) {
    super(id, name, heading, isStart);

    this.question = question;
    this.answers = answers;
    this.help = help;
  }

  question: string;
  answers: FlowAnswer<string>[];
  help?: FlowHelp;

  public getNextId(answer?: IAnswer): number | undefined {
    const castedAnswer: IStringAnswer | undefined = answer;

    const nextItemConfig = this.answers.find(
      (x) => x.value === castedAnswer?.value
    )?.nextItemConfig;

    return FlowNextItemConfig.getIdOfNextItem(nextItemConfig);
  }

  public generateReactElement(
    configData: IConfigurationDataRaw | undefined,
    completed: (answ: IAnswer) => void,
    helpClicked?: (helpData: IHelp) => void
  ): React.ReactElement<IInteractionItemProps<IAnswer>> {
    const helpAvailable = this.help ? true : false;
    let helpClickedAdjusted = () => {};

    if (helpAvailable && helpClicked) {
      helpClickedAdjusted = () => {
        helpClicked({
          imageUrl: this.help?.imageUrl,
          text: this.help!.text,
        });
      };
    }

    return (
      <SingleAnswer
        id={this.id}
        name={this.name}
        heading={this.heading}
        question={this.question}
        answers={this.answers.map((x) => x.value)}
        completed={completed}
        helpAvailable={this.help ? true : false}
        helpClicked={helpClickedAdjusted}
      />
    );
  }
}
