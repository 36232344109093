import * as React from "react";
import { Button } from "react-bootstrap";
import "./WelcomeScreen.css";

interface IWelcomeScreenProps {
  text: string;
  startPossible: boolean;

  start(): void;
}

const WelcomeScreen: React.FunctionComponent<IWelcomeScreenProps> = (props) => {
  return (
    <div className="center-item">
      <div>{props.text}</div>
      <div className="mt-5 ">
        <Button
          disabled={!props.startPossible}
          variant="rk-red"
          onClick={() => props.start()}
        >
          Starten
        </Button>
      </div>
    </div>
  );
};

export default WelcomeScreen;
