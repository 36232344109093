import { FlowNextItemConfig } from './FlowNextItemConfig';

export class FlowAnswer<T> {
  constructor(value: T, nextItemConfig?: FlowNextItemConfig[]) {
    this.value = value;
    this.nextItemConfig = nextItemConfig;
  }

  value: T;
  nextItemConfig?: FlowNextItemConfig[];
}
