import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainContentContainer from "./components/main-content-container/MainContentContainer";
import useResizeObserver from "use-resize-observer";

const App: React.FunctionComponent = () => {
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      const parent = window.parent;

      if (parent) {
        parent.postMessage(
          {
            height: height,
          },
          "*"
        );
      }
    },
  });

  return (
    <div ref={ref} className="centered">
      <MainContentContainer />
    </div>
  );
};

export default App;
