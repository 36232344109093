import * as React from "react";
import "./MainContentContainer.css";
import { Card, Spinner } from "react-bootstrap";
import logo from "../../images/oerk_tirol.jpg";
import WelcomeScreen from "../interaction-items/welcome-screen/WelcomeScreen";
import { IInteractionItemProps } from "../../interfaces/IInteractionItemProps";
import { IAnswer } from "../../interfaces/IAnswer";
import { IStringAnswer } from "../../interfaces/IStringAnswer";
import {
  ISavedFlowNextItemFromAnswerGenProps,
  SavedFlow,
} from "../../flow/SavedFlow";
import HelpInformationViewer from "../interaction-items/help-information-viewer/HelpInformationViewer";
import { IHelp } from "../../interfaces/IHelp";
import InteractionContentContainer from "../interaction-content-container/InteractionContentContainer";
import { IConfigurationDataRaw } from "../../interfaces/ICostRawData";

export interface IMainContentContainerProps {}

export interface IMainContentContainerState {
  isStart: boolean;
  loadedData: boolean;
  showHelp: boolean;
  interactionItems: React.ReactElement<IInteractionItemProps<IAnswer>>[];
  answers: IStringAnswer[];
}

export const ConfigurationContext = React.createContext<
  IConfigurationDataRaw | undefined
>(undefined);

export default class MainContentContainer extends React.Component<
  IMainContentContainerProps,
  IMainContentContainerState
> {
  private savedFlow?: SavedFlow;
  private helpData?: IHelp;
  private welcomeText: string = "";
  private configData?: IConfigurationDataRaw;

  constructor(props: IMainContentContainerProps) {
    super(props);

    this.state = {
      isStart: true,
      loadedData: false,
      showHelp: false,
      interactionItems: [],
      answers: [],
    };
  }

  componentDidMount() {
    fetch(this.getConfigurationUrl())
      .then((res) => res.json())
      .then((item) => {
        this.savedFlow = new SavedFlow(item);
        this.welcomeText = item.welcomeText;
        this.configData = item;

        this.setState({
          interactionItems: [
            this.savedFlow.getFirstItem(
              this.configData,
              (answer) => this.questionCompleted(answer),
              (helpData) => this.showHelp(helpData)
            ),
          ],
          loadedData: true,
        });
      })
      .catch(() => {
        throw new Error("Could not load configuration");
      });

    /*const item = require('../../data/configuration.json');

    this.savedFlow = new SavedFlow(item);
    this.welcomeText = item.welcomeText;
    this.configData = item;

    this.setState({
      interactionItems: [
        this.savedFlow.getFirstItem(
          this.configData,
          (answer) => this.questionCompleted(answer),
          (helpData) => this.showHelp(helpData)
        ),
      ],
      loadedData: true,
    });*/
  }

  private getConfigurationUrl(): string {
    const params = new URLSearchParams(window.location.search);

    let configUrl: string | undefined;

    if (params.has("useTestConfig") && params.get("useTestConfig") === "true") {
      configUrl = process.env.REACT_APP_TEST_CONFIG_URL;
    } else {
      configUrl = process.env.REACT_APP_CONFIG_URL;
    }

    if (!configUrl) {
      throw new Error("No configuration url specified");
    }

    return configUrl;
  }

  private questionCompleted(answer: IAnswer) {
    const generationData: ISavedFlowNextItemFromAnswerGenProps = {
      answer: answer,
      completed: (answer) => this.questionCompleted(answer),
      helpClicked: (helpData) => this.showHelp(helpData),
    };

    const nextItem = this.savedFlow!.getNextItemFromAnswer(
      this.configData,
      generationData
    );

    if (nextItem) {
      const index = this.state.interactionItems.findIndex(
        (x) => x.props.id === answer.id
      );

      const newItems = this.state.interactionItems.slice(0, index + 1);

      this.setState({
        interactionItems: newItems,
      });

      setTimeout(() => {
        newItems.push(nextItem);

        this.setState({
          interactionItems: newItems,
        });

        setTimeout(
          () => InteractionContentContainer.openContainer(nextItem.props.id),
          150
        );
      }, 75);
    }
  }

  private showHelp(helpData: IHelp): void {
    this.helpData = helpData;
    this.setState({
      showHelp: true,
    });
  }

  private getContent() {
    if (!this.state.loadedData) {
      return (
        <div className="d-flex justify-content-center">
          <Spinner className="center-spinner" animation="border" />
        </div>
      );
    }

    if (this.state.isStart) {
      return (
        <WelcomeScreen
          startPossible={this.state.loadedData}
          text={this.welcomeText}
          start={() => this.setState({ isStart: false })}
        />
      );
    } else {
      return (
        <div>
          {this.state.showHelp ? (
            <div>
              <HelpInformationViewer
                imageUrl={this.helpData?.imageUrl}
                text={this.helpData!.text}
                back={() =>
                  this.setState({
                    showHelp: false,
                  })
                }
              />
            </div>
          ) : null}

          <div className={this.state.showHelp ? "no-display" : ""}>
            {this.state.interactionItems.map((x) => {
              return <div key={x.props.id}>{x}</div>;
            })}
          </div>
        </div>
      );
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Card style={{ width: "36rem" }} className="m-3 shadow">
          <Card.Body style={{ padding: "20px" }}>
            <img id="card-image" src={logo} alt="Logo Rotes Kreuz Tirol"></img>
            <Card.Title>Wegweiser</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Rotes Kreuz Tirol
            </Card.Subtitle>

            <ConfigurationContext.Provider value={this.configData}>
              <div className="mt-5 pb-3">{this.getContent()}</div>
            </ConfigurationContext.Provider>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}
