import ResultViewer from "../components/interaction-items/result-viewer/ResultViewer";
import { IAnswer } from "../interfaces/IAnswer";
import { IContactInformation } from "../interfaces/IContactInformation";
import { IConfigurationDataRaw } from "../interfaces/ICostRawData";
import { IHelp } from "../interfaces/IHelp";
import { IInteractionItemProps } from "../interfaces/IInteractionItemProps";
import { IPriceInformation } from "../interfaces/IPriceInformation";
import { FlowItem } from "./FlowItem";

export class FlowResultViewer extends FlowItem {
  constructor(
    id: number,
    name: string,
    heading: string,
    greenText?: string,
    yellowText?: string,
    text?: string,
    finePrint?: string,
    contactInformation?: IContactInformation,
    priceInformation?: IPriceInformation,
    isStart?: boolean
  ) {
    super(id, name, heading, isStart);

    this.text = text;
    this.greenText = greenText;
    this.yellowText = yellowText;
    this.text = text;
    this.finePrint = finePrint;
    this.contactInformation = contactInformation;
    this.priceInformation = priceInformation;
  }

  greenText?: string;
  yellowText?: string;
  text?: string;
  finePrint?: string;
  contactInformation?: IContactInformation;
  priceInformation?: IPriceInformation;

  public getNextId(): number | undefined {
    return undefined;
  }

  public generateReactElement(
    configData: IConfigurationDataRaw | undefined,
    completed: (answ: IAnswer) => void,
    _: (helpData: IHelp) => void,
    kilometer?: number,
    locationHint?: string
  ): React.ReactElement<IInteractionItemProps<IAnswer>> {
    return (
      <ResultViewer
        id={this.id}
        name={this.name}
        heading={this.heading}
        text={this.text}
        finePrint={this.finePrint}
        greenText={this.greenText}
        yellowText={this.yellowText}
        contactInformation={this.contactInformation}
        priceInformation={this.priceInformation}
        kilometer={kilometer!}
        locationHint={locationHint}
        configData={configData}
        completed={completed}
        helpAvailable={false}
      />
    );
  }
}
