import { DistrictProvider } from '../services/DistrictProvider';

export class FlowNextItemConfig {
  constructor(
    idOfNext: number,
    districtFilter: string | undefined = undefined
  ) {
    this.idOfNext = idOfNext;
    this.districtFilter = districtFilter;
  }

  idOfNext: number;
  districtFilter?: string;

  public static getIdOfNextItem(
    items: FlowNextItemConfig[] | undefined
  ): number | undefined {
    if (items) {
      const district = DistrictProvider.getSpecifiedDistrict();

      for (let i = 0; i < items.length; i++) {
        const currentItem = items[i];

        if (!currentItem.districtFilter) {
          return currentItem.idOfNext;
        } else if (currentItem.districtFilter === district) {
          return currentItem.idOfNext;
        }
      }
    }

    return undefined;
  }
}
