import * as React from "react";
import { Button } from "react-bootstrap";
import "../../../shared-css/rk-btn.css";
import infoIcon from "../../../images/info_icon.svg";
import "./SingleAnswer.css";
import { IInteractionItemProps } from "../../../interfaces/IInteractionItemProps";
import InteractionContentContainer from "../../interaction-content-container/InteractionContentContainer";
import { IStringAnswer } from "../../../interfaces/IStringAnswer";

interface ISingleAnswerProps extends IInteractionItemProps<IStringAnswer> {
  heading: string;
  question: string;
  answers: string[];
}

interface ISingleAnswerState {
  answer?: string;
}

export default class SingleAnswer extends React.Component<
  ISingleAnswerProps,
  ISingleAnswerState
> {
  constructor(props: ISingleAnswerProps) {
    super(props);

    this.state = {
      answer: undefined,
    };
  }

  private answerClicked(selectedAnswer: string) {
    this.setState({
      answer: selectedAnswer,
    });

    InteractionContentContainer.collapseContainer(this.props.id);

    this.props.completed({
      id: this.props.id,
      value: selectedAnswer,
    });
  }

  private generateAnswers(): JSX.Element[] {
    return this.props.answers.map((x) => {
      return (
        <div key={x}>
          <Button
            className="mb-2"
            variant="rk-red"
            onClick={() => this.answerClicked(x)}
          >
            {x}
          </Button>
        </div>
      );
    });
  }

  public render() {
    return (
      <InteractionContentContainer
        heading={this.props.heading}
        id={this.props.id}
        badgeText={this.state.answer}
      >
        <div className="centered-text">
          <div>
            {this.props.question}{" "}
            {this.props.helpAvailable ? (
              <span onClick={this.props.helpClicked}>
                <img
                  src={infoIcon}
                  className="info-icon"
                  alt="Hilfe anzeigen"
                />
              </span>
            ) : null}
          </div>
          <div className="mt-4">{this.generateAnswers()}</div>
        </div>
      </InteractionContentContainer>
    );
  }
}
