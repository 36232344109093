import * as React from "react";
import "./ResultViewer.css";
import { Alert, Card } from "react-bootstrap";
import { IAnswer } from "../../../interfaces/IAnswer";
import { IContactInformation } from "../../../interfaces/IContactInformation";
import { IInteractionItemProps } from "../../../interfaces/IInteractionItemProps";
import InteractionContentContainer from "../../interaction-content-container/InteractionContentContainer";
import phoneIcon from "../../../images/phone.svg";
import { IPriceInformation } from "../../../interfaces/IPriceInformation";
import { CostCalculator } from "../../../services/CostCalculator";
import { IConfigurationDataRaw } from "../../../interfaces/ICostRawData";
import { DistrictProvider } from "../../../services/DistrictProvider";

export interface IResultViewerProps extends IInteractionItemProps<IAnswer> {
  heading: string;
  kilometer: number;
  locationHint?: string;

  greenText?: string;
  yellowText?: string;
  text?: string;
  finePrint?: string;

  contactInformation?: IContactInformation;
  priceInformation?: IPriceInformation;

  configData?: IConfigurationDataRaw;
}

export default class ResultViewer extends React.Component<IResultViewerProps> {
  private costCalculator: CostCalculator;

  constructor(props: IResultViewerProps) {
    super(props);

    if (this.props.configData) {
      this.costCalculator = new CostCalculator(this.props.configData);
    } else {
      throw new Error("Could not load data for cost calculation");
    }
  }

  private getAlert(variant: string, text?: string) {
    if (text) {
      return <Alert variant={variant}>{text}</Alert>;
    }

    return null;
  }

  private getPricingSection() {
    if (this.props.priceInformation && this.props.kilometer) {
      return (
        <div>
          <Alert variant="info">
            {this.props.priceInformation.text +
              " € " +
              this.costCalculator.getCostsForKilometers(
                this.props.kilometer,
                this.props.priceInformation.zoneIdentifier,
                DistrictProvider.getSpecifiedDistrict(),
                this.props.locationHint
              )}
            <br />
            {this.props.priceInformation.textBelow ? (
              <React.Fragment>
                <br />
                {this.props.priceInformation.textBelow}
              </React.Fragment>
            ) : null}
          </Alert>
        </div>
      );
    }
  }

  private getBookingSection() {
    if (this.props.contactInformation) {
      return (
        <div className="center-item mt-4">
          <hr />
          <div className="mt-4">{this.props.contactInformation.text}</div>
          <div className="mt-4">
            <h2>{this.props.contactInformation.name}</h2>
          </div>

          <div className="phone-number">
            <a
              className="rk-font-color"
              href={"tel:" + this.props.contactInformation.phoneNumber}
            >
              <span onClick={this.props.helpClicked} className="me-3">
                <img src={phoneIcon} className="phone-icon" alt="Anrufen" />
              </span>

              {this.props.contactInformation.phoneNumber}
            </a>
          </div>
        </div>
      );
    }
  }

  private getFinePrint() {
    if (this.props.finePrint) {
      return (
        <Card.Text className="mt-4 mb-2 blockquote-footer">
          {this.props.finePrint}
        </Card.Text>
      );
    }
  }

  public render() {
    return (
      <React.Fragment>
        <InteractionContentContainer
          heading={this.props.heading}
          id={this.props.id}
        >
          {this.getAlert("success", this.props.greenText)}
          {this.getAlert("warning", this.props.yellowText)}

          {this.getPricingSection()}

          <div className="center-item">{this.props.text}</div>

          {this.getBookingSection()}

          {this.getFinePrint()}
        </InteractionContentContainer>
      </React.Fragment>
    );
  }
}
