import * as React from "react";
import {
  Spinner,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
} from "react-bootstrap";
import { IInteractionItemProps } from "../../../interfaces/IInteractionItemProps";
import { IKilometerAnswer } from "../../../interfaces/IKilometerAnswer";
import InteractionContentContainer from "../../interaction-content-container/InteractionContentContainer";
import AddressInput from "./address-input/AddressInput";
import "./AdvancedKilometerFinder.css";
import PlaceSuggestion from "./place-suggestion/PlaceSuggestion";
import { ConfigurationContext } from "../../main-content-container/MainContentContainer";
import { IGeolocatedAddress } from "../../../interfaces/IGeolocatedAddress";

export interface IAdvancedKilometerFinderProps
  extends IInteractionItemProps<IKilometerAnswer> {
  heading: string;
  pickupText: string;
  destinationText: string;
  placeSuggestionInfoText?: string;
  useOegkKilometer?: boolean;
}

interface IAdvancedKilometerFinderState {
  status: NavigationStatus;
  kilometer?: number;
  destinationSearchType: SearchType;
}

enum NavigationStatus {
  PICK_UP,
  DESTINATION,
  LOADING,
}

enum SearchType {
  PLACE,
  ADDRESS,
}

export default class AdvancedKilometerFinder extends React.Component<
  IAdvancedKilometerFinderProps,
  IAdvancedKilometerFinderState
> {
  private ROUTING_BASE_URL = process.env.REACT_APP_OSRM_URL;
  private OEGK_API_BASE_URL = process.env.REACT_APP_OEGK_API_URL;

  private pickupGeolocatedAddress?: IGeolocatedAddress;

  private destinationGeolocatedAddress?: IGeolocatedAddress;

  constructor(props: IAdvancedKilometerFinderProps) {
    super(props);

    this.state = {
      status: NavigationStatus.PICK_UP,
      destinationSearchType: SearchType.PLACE,
    };
  }

  private tabChanged(value: number) {
    if (value === 1) {
      this.setState({ destinationSearchType: SearchType.PLACE });
    } else {
      this.setState({ destinationSearchType: SearchType.ADDRESS });
    }
  }

  private getDestinationSearch() {
    switch (this.state.destinationSearchType) {
      case SearchType.PLACE:
        return (
          <div>
            {this.props.placeSuggestionInfoText ? (
              <Alert variant="info" className="mx-3">
                {this.props.placeSuggestionInfoText}
              </Alert>
            ) : null}

            <div className="mt-4">
              <ConfigurationContext.Consumer>
                {(value) => (
                  <PlaceSuggestion
                    configData={value}
                    geolocatedAddressAvailable={(address) => {
                      this.destinationGeolocationSet(address);
                    }}
                  />
                )}
              </ConfigurationContext.Consumer>
            </div>
          </div>
        );

      case SearchType.ADDRESS:
        return (
          <AddressInput
            geolocatedAddressAvailable={(address) => {
              this.destinationGeolocationSet(address);
            }}
          />
        );
    }
  }

  private getContent() {
    switch (this.state.status) {
      case NavigationStatus.PICK_UP:
        return (
          <div>
            <div className="center-item">
              {this.props.pickupText}
              <div className="mt-5">
                <h3>Abholort</h3>
              </div>
            </div>

            <div className="mt-5">
              <AddressInput
                geolocatedAddressAvailable={(address) => {
                  this.pickupGeolocationSet(address);
                }}
              />
            </div>
          </div>
        );

      case NavigationStatus.DESTINATION:
        return (
          <div>
            <div className="center-item">
              {this.props.destinationText}
              <div className="mt-5">
                <h3>Zielort</h3>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-5">
              <ToggleButtonGroup
                type="radio"
                name="options"
                onChange={(value) => this.tabChanged(value)}
                defaultValue={1}
              >
                <ToggleButton id="1" variant="rk-red rk-red-toggle" value={1}>
                  Einrichtung
                </ToggleButton>
                <ToggleButton id="2" variant="rk-red rk-red-toggle" value={2}>
                  Adresse
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div className="mt-5">{this.getDestinationSearch()}</div>
          </div>
        );

      default:
        return (
          <div>
            <div className="center-item">
              Einen Moment bitte, wir berechnen die Fahrtkilometer zwischen den
              zwei eingegeben Adressen.
              <div className="mt-5 mb-4 ">
                <Spinner animation="border" />
              </div>
            </div>
          </div>
        );
    }
  }

  private pickupGeolocationSet(address: IGeolocatedAddress): void {
    this.pickupGeolocatedAddress = address;

    this.setState({ status: NavigationStatus.DESTINATION });
  }

  private destinationGeolocationSet(address: IGeolocatedAddress): void {
    this.destinationGeolocatedAddress = address;

    if (
      this.props.useOegkKilometer === true &&
      this.pickupGeolocatedAddress?.localityId &&
      this.destinationGeolocatedAddress?.localityId
    ) {
      this.getKilometerByOegk();
    } else {
      this.getKilometerByRoute();
    }
  }

  private getKilometerByOegk(): void {
    const queryParam = new URLSearchParams();
    queryParam.append(
      "start_postal_code",
      this.pickupGeolocatedAddress?.postalCode ?? ""
    );
    queryParam.append("start_city", this.pickupGeolocatedAddress?.city ?? "");
    queryParam.append(
      "start_street",
      this.pickupGeolocatedAddress?.street ?? ""
    );
    queryParam.append(
      "start_house_number",
      this.pickupGeolocatedAddress?.houseNumber ?? ""
    );
    queryParam.append(
      "start_locality_id",
      this.pickupGeolocatedAddress?.localityId?.toString() ?? ""
    );

    queryParam.append(
      "end_postal_code",
      this.destinationGeolocatedAddress?.postalCode ?? ""
    );
    queryParam.append(
      "end_city",
      this.destinationGeolocatedAddress?.city ?? ""
    );
    queryParam.append(
      "end_street",
      this.destinationGeolocatedAddress?.street ?? ""
    );
    queryParam.append(
      "end_house_number",
      this.destinationGeolocatedAddress?.houseNumber ?? ""
    );
    queryParam.append(
      "end_locality_id",
      this.destinationGeolocatedAddress?.localityId?.toString() ?? ""
    );

    const requestUrl: string =
      this.OEGK_API_BASE_URL + `/get-kilometer?${queryParam.toString()}`;

    fetch(requestUrl)
      .then((res) => res.json())
      .then((output: IOegkKilometerApiResponse) => {
        if (output.kilometer) {
          const kilometer = Math.round(output.kilometer);

          this.setState({
            kilometer: kilometer,
            status: NavigationStatus.PICK_UP,
          });

          InteractionContentContainer.collapseContainer(this.props.id);

          let locationHint: string | undefined = undefined;

          if (
            this.pickupGeolocatedAddress?.postalCode === "6020" &&
            this.destinationGeolocatedAddress?.postalCode === "6020"
          ) {
            locationHint = "ibk";
          }

          this.props.completed({
            id: this.props.id,
            kilometer: kilometer,
            locationHint: locationHint,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    this.setState({ status: NavigationStatus.LOADING });
  }

  private getKilometerByRoute(): void {
    const routeUrl: string =
      this.ROUTING_BASE_URL +
      "/route/v1/driving/" +
      this.pickupGeolocatedAddress?.longitude?.toString() +
      "," +
      this.pickupGeolocatedAddress?.latitude?.toString() +
      ";" +
      this.destinationGeolocatedAddress?.longitude?.toString() +
      "," +
      this.destinationGeolocatedAddress?.latitude?.toString() +
      "?steps=false";

    fetch(routeUrl)
      .then((res) => res.json())
      .then((output: IRouteService) => {
        if (output.routes.length === 1) {
          const chosenRoute = output.routes[0];
          const kilometer = Math.round(chosenRoute.distance / 1000);

          this.setState({
            kilometer: kilometer,
            status: NavigationStatus.PICK_UP,
          });

          InteractionContentContainer.collapseContainer(this.props.id);

          this.props.completed({
            id: this.props.id,
            kilometer: kilometer,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    this.setState({ status: NavigationStatus.LOADING });
  }

  public render() {
    return (
      <InteractionContentContainer
        heading={this.props.heading}
        id={this.props.id}
        badgeText={
          this.state.kilometer ? this.state.kilometer + " km" : undefined
        }
        isOverflowVisible={true}
      >
        {this.getContent()}
      </InteractionContentContainer>
    );
  }
}

interface IRouteService {
  routes: IRoute[];
}

interface IRoute {
  distance: number;
}

interface IOegkKilometerApiResponse {
  kilometer?: number;
}
