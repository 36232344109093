import AdvancedKilometerFinder from "../components/interaction-items/kilometer-finder/AdvancedKilometerFinder";
import { IAnswer } from "../interfaces/IAnswer";
import { IConfigurationDataRaw } from "../interfaces/ICostRawData";
import { IHelp } from "../interfaces/IHelp";
import { IInteractionItemProps } from "../interfaces/IInteractionItemProps";
import { FlowItem } from "./FlowItem";
import { FlowNextItemConfig } from "./FlowNextItemConfig";

export class FlowKilometerFinder extends FlowItem {
  constructor(
    id: number,
    name: string,
    heading: string,
    nextItemConfig: FlowNextItemConfig[] | undefined,
    pickupText: string,
    destinationText: string,
    placeSuggestionInfoText?: string,
    useOegkKilometer?: boolean,

    isStart?: boolean
  ) {
    super(id, name, heading, isStart);

    this.nextItemConfig = nextItemConfig;
    this.pickupText = pickupText;
    this.destinationText = destinationText;
    this.placeSuggestionInfoText = placeSuggestionInfoText;
    this.useOegkKilometer = useOegkKilometer;
  }

  nextItemConfig: FlowNextItemConfig[] | undefined;
  pickupText: string;
  destinationText: string;
  placeSuggestionInfoText?: string;
  useOegkKilometer?: boolean;

  public getNextId(): number | undefined {
    return FlowNextItemConfig.getIdOfNextItem(this.nextItemConfig);
  }

  public generateReactElement(
    configData: IConfigurationDataRaw | undefined,
    completed: (answ: IAnswer) => void,
    _: (helpData: IHelp) => void,
    kilometer?: number,
    locationHint?: string
  ): React.ReactElement<IInteractionItemProps<IAnswer>> {
    return (
      <AdvancedKilometerFinder
        id={this.id}
        name={this.name}
        heading={this.heading}
        pickupText={this.pickupText}
        destinationText={this.destinationText}
        placeSuggestionInfoText={this.placeSuggestionInfoText}
        useOegkKilometer={this.useOegkKilometer}
        completed={completed}
        helpAvailable={false}
      />
    );
  }
}
