import "./PlaceSuggestion.css";

import * as React from "react";
import { PlaceSuggestionProvider } from "../../../../services/PlaceSuggestionProvider";
import { IConfigurationDataRaw } from "../../../../interfaces/ICostRawData";
import Autosuggest from "react-autosuggest";
import { Button } from "react-bootstrap";
import { IGeolocatedAddress } from "../../../../interfaces/IGeolocatedAddress";

export interface IPlaceSuggestionProps {
  configData?: IConfigurationDataRaw;

  geolocatedAddressAvailable(address: IGeolocatedAddress): void;
}

interface IPlaceSuggestionState {
  suggestionPlaces: string[];
  placeName: string;
  continueAvailable: boolean;
}

export default class PlaceSuggestion extends React.Component<
  IPlaceSuggestionProps,
  IPlaceSuggestionState
> {
  private suggestionProvider: PlaceSuggestionProvider;

  constructor(props: IPlaceSuggestionProps) {
    super(props);

    if (this.props.configData) {
      this.suggestionProvider = new PlaceSuggestionProvider(
        this.props.configData
      );
    } else {
      throw new Error("Could not load data for place suggestion");
    }

    this.state = {
      suggestionPlaces: [],
      placeName: "",
      continueAvailable: false,
    };
  }

  getSuggestedPlaces(): React.ReactElement {
    return (
      <ul>
        {this.state.suggestionPlaces.map((name) => {
          return <li key={name}>{name}</li>;
        })}
      </ul>
    );
  }

  private getPlaceSuggestions(name: string): void {
    this.setState({
      suggestionPlaces: this.suggestionProvider.getSuggestions(name),
    });
  }

  private getInputComponent(inputProps: any) {
    return (
      <div className="floating-label-container">
        <input {...inputProps} />
        <label className="react-autosuggest__label">
          {inputProps.placeholder}
        </label>
      </div>
    );
  }

  private preparePlaceItem(place: string) {
    return <span>{place}</span>;
  }

  private getPlaceValue(place: string) {
    return place;
  }

  private placeNameChanged(value: string) {
    if (this.suggestionProvider.checkIfExists(value)) {
      this.setState({ continueAvailable: true, placeName: value });
    } else {
      this.setState({ continueAvailable: false, placeName: value });
    }
  }

  private continueClicked(): void {
    const geolocatedAddress = this.suggestionProvider.getGeolocatedAddress(
      this.state.placeName
    );

    this.props.geolocatedAddressAvailable(geolocatedAddress);
  }

  public render() {
    const searchFieldInputProps = {
      placeholder: "Name der Einrichtung",
      value: this.state.placeName,
      onChange: (_: any, { newValue }: any) => {
        this.placeNameChanged(newValue);
      },
      className: "me-1 react-autosuggest__input full-width",
      spellCheck: false,
      autoComplete: "off",
    };

    return (
      <div className="mx-3">
        <Autosuggest
          suggestions={this.state.suggestionPlaces}
          inputProps={searchFieldInputProps}
          onSuggestionsClearRequested={() =>
            this.setState({ suggestionPlaces: [] })
          }
          renderInputComponent={this.getInputComponent}
          renderSuggestion={this.preparePlaceItem}
          getSuggestionValue={this.getPlaceValue}
          onSuggestionsFetchRequested={({ value }: any) =>
            this.getPlaceSuggestions(value as string)
          }
        />

        <div>
          <Button
            disabled={!this.state.continueAvailable}
            variant="rk-red"
            className="full-width mt-2"
            onClick={() => this.continueClicked()}
          >
            Weiter
          </Button>
        </div>
      </div>
    );
  }
}
