import { compareTwoStrings } from "string-similarity";
import { IPlace } from "../interfaces/IPlace";
import { IConfigurationDataRaw } from "../interfaces/ICostRawData";
import { IGeolocatedAddress } from "../interfaces/IGeolocatedAddress";

export class PlaceSuggestionProvider {
  private places: IPlace[];
  private placesString: string[];

  constructor(data: IConfigurationDataRaw) {
    this.places = data.suggestionPlaces as IPlace[];
    this.placesString = this.places.map((x) => x.name);
  }

  static fromJson(json: string) {
    const parsedJson = JSON.parse(json);
    return new PlaceSuggestionProvider(parsedJson);
  }

  public getSuggestions(name: string): string[] {
    var scoredItems: IScoredItem<string>[] = [];

    this.placesString.forEach((placeName) => {
      const score = compareTwoStrings(name, placeName);

      scoredItems.push({ score: score, item: placeName });
    });

    return scoredItems
      .sort((a, b) => b.score - a.score)
      .slice(0, 5)
      .map((x) => x.item);
  }

  public getGeolocatedAddress(name: string): IGeolocatedAddress {
    const item = this.places.find((x) => x.name === name);

    if (item) {
      return item;
    }

    throw new Error("Could not find GPS coordinates for given name");
  }

  public checkIfExists(name: string): boolean {
    return this.places.find((x) => x.name === name) !== undefined;
  }
}

interface IScoredItem<T> {
  score: number;
  item: T;
}
