import * as React from 'react';
import './HelpInformationViewer.css';
import { Button } from 'react-bootstrap';

interface IHelpInformationViewerProps {
  imageUrl?: string;
  text: string;

  back(): void;
}

const HelpInformationViewer: React.FunctionComponent<IHelpInformationViewerProps> =
  (props) => {
    return (
      <div className="center-item">
        {props.imageUrl ? (
          <a href={props.imageUrl} target="_blank" rel="noreferrer">
            <img
              className="help-image mb-5"
              src={props.imageUrl}
              alt="Bild zur Visualisierung des Hilfetexts"
            />
          </a>
        ) : null}
        <div>{props.text}</div>
        <div className="mt-5">
          <Button variant="rk-red" onClick={() => props.back()}>
            Zurück
          </Button>
        </div>
      </div>
    );
  };

export default HelpInformationViewer;
