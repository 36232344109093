import { IAnswer } from "../interfaces/IAnswer";
import { IConfigurationDataRaw } from "../interfaces/ICostRawData";
import { IHelp } from "../interfaces/IHelp";
import { IInteractionItemProps } from "../interfaces/IInteractionItemProps";

export abstract class FlowItem {
  constructor(id: number, name: string, heading: string, isStart?: boolean) {
    this.id = id;
    this.name = name;
    this.heading = heading;
    this.isStart = isStart ? true : false;
  }

  id: number;
  name: string;
  heading: string;
  isStart: boolean;

  public abstract getNextId(answer?: IAnswer): number | undefined;

  public abstract generateReactElement(
    configData: IConfigurationDataRaw | undefined,
    completed: (answ: IAnswer) => void,
    helpClicked?: (helpData: IHelp) => void,
    kilometer?: number,
    locationHint?: string
  ): React.ReactElement<IInteractionItemProps<IAnswer>>;
}
