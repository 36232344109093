import React from "react";
import { IAnswer } from "../interfaces/IAnswer";
import { IContactInformation } from "../interfaces/IContactInformation";
import { IConfigurationDataRaw } from "../interfaces/ICostRawData";
import { IHelp } from "../interfaces/IHelp";
import { IInteractionItemProps } from "../interfaces/IInteractionItemProps";
import { IKilometerAnswer } from "../interfaces/IKilometerAnswer";
import { IPriceInformation } from "../interfaces/IPriceInformation";
import { FlowAnswer } from "./FlowAnswer";
import { FlowHelp } from "./FlowHelp";
import { FlowItem } from "./FlowItem";
import { FlowKilometerFinder } from "./FlowKilometerFinder";
import { FlowNextItemConfig } from "./FlowNextItemConfig";
import { FlowResultViewer } from "./FlowResultViewer";
import { FlowSingleAnswer } from "./FlowSingleAnswer";

export interface ISavedFlowNextItemFromAnswerGenProps {
  answer: IAnswer;
  completed: (answ: IAnswer) => void;
  helpClicked?: (helpData: IHelp) => void;
  kilometer?: number;
}

export class SavedFlow {
  private flowItems: Record<number, FlowItem>;

  constructor(flow: ISavedFlowRaw) {
    this.flowItems = {};

    this.parseSingleAnswers(flow.singleAnswers);
    this.parseResultViewers(flow.resultViewers);
    this.parseKilometerFinders(flow.kilometerFinders);
  }

  static fromJson(json: string) {
    const parsedJson: ISavedFlowRaw = JSON.parse(json);
    return new SavedFlow(parsedJson);
  }

  private parseSingleAnswers(singleAnswers: ISavedFlowSingleAnswerRaw[]): void {
    singleAnswers.forEach((item) => {
      const answers = item.answers.map((x) => {
        let nextItemConfig: FlowNextItemConfig[] | undefined;

        if (x.nextItemConfig) {
          nextItemConfig = x.nextItemConfig.map(
            (y) => new FlowNextItemConfig(y.idOfNext, y.districtFilter)
          );
        }

        return new FlowAnswer<string>(x.text, nextItemConfig);
      });

      let help: FlowHelp | undefined = undefined;

      if (item.help) {
        help = new FlowHelp(item.help.imageUrl, item.help.text);
      }

      const mainItem = new FlowSingleAnswer(
        item.id,
        item.name,
        item.heading,
        item.question,
        answers,
        item.isStart,
        help
      );

      this.flowItems[item.id] = mainItem;
    });
  }

  private parseResultViewers(resultViewers: ISavedFlowResultViewerRaw[]): void {
    resultViewers.forEach((item) => {
      let contactInformation: IContactInformation | undefined = undefined;

      if (item.contactInformation) {
        contactInformation = {
          text: item.contactInformation.text,
          phoneNumber: item.contactInformation.phoneNumber,
          name: item.contactInformation.name,
        };
      }

      let priceInformation: IPriceInformation | undefined = undefined;

      if (item.priceInformation) {
        priceInformation = {
          zoneIdentifier: item.priceInformation.zoneIdentifier,
          text: item.priceInformation.text,
          textBelow: item.priceInformation.textBelow,
        };
      }

      const mainItem = new FlowResultViewer(
        item.id,
        item.name,
        item.heading,
        item.greenText,
        item.yellowText,
        item.text,
        item.finePrint,
        contactInformation,
        priceInformation
      );

      this.flowItems[item.id] = mainItem;
    });
  }

  private parseKilometerFinders(
    kilometerFinders: ISavedFlowKilometerFinderRaw[]
  ): void {
    kilometerFinders.forEach((item) => {
      let nextItemConfig: FlowNextItemConfig[] | undefined;

      if (item.nextItemConfig) {
        nextItemConfig = item.nextItemConfig.map(
          (x) => new FlowNextItemConfig(x.idOfNext, x.districtFilter)
        );
      }

      const mainItem = new FlowKilometerFinder(
        item.id,
        item.name,
        item.heading,
        nextItemConfig,
        item.pickupText,
        item.destinationText,
        item.placeSuggestionInfoText,
        item.useOegkKilometer
      );

      this.flowItems[item.id] = mainItem;
    });
  }

  public getNextItemFromAnswer(
    configData: IConfigurationDataRaw | undefined,
    generationProps: ISavedFlowNextItemFromAnswerGenProps
  ): React.ReactElement<IInteractionItemProps<IAnswer>> {
    const nextId = this.flowItems[generationProps.answer.id]?.getNextId(
      generationProps.answer
    )!;

    const castedIem = generationProps.answer as IKilometerAnswer;
    let kilometer = undefined;
    let locationHint = castedIem.locationHint;

    if (castedIem.kilometer) {
      kilometer = castedIem.kilometer;
    }

    return this.flowItems[nextId]!.generateReactElement(
      configData,
      generationProps.completed,
      generationProps.helpClicked,
      kilometer,
      locationHint
    );
  }

  public getFirstItem(
    configData: IConfigurationDataRaw | undefined,
    completed: (answ: IAnswer) => void,
    helpClicked?: (helpData: IHelp) => void
  ): React.ReactElement<IInteractionItemProps<IAnswer>> {
    const item = Object.values(this.flowItems).find((x) => x.isStart)!;

    return item.generateReactElement(configData, completed, helpClicked);
  }
}

// JSON RAW CLASSES

interface ISavedFlowRaw {
  singleAnswers: ISavedFlowSingleAnswerRaw[];
  kilometerFinders: ISavedFlowKilometerFinderRaw[];
  resultViewers: ISavedFlowResultViewerRaw[];
}

interface ISavedFlowItemRaw {
  id: number;
  name: string;
  heading: string;
  isStart?: boolean;
}

interface ISavedFlowSingleAnswerRaw extends ISavedFlowItemRaw {
  question: string;
  answers: ISavedFlowItemAnswerRaw[];
  help?: ISavedFlowItemHelpRaw;
}

interface ISavedFlowResultViewerRaw extends ISavedFlowItemRaw {
  greenText?: string;
  yellowText?: string;
  text?: string;
  finePrint?: string;

  contactInformation?: ISavedFlowContactInformation;
  priceInformation?: ISavedFlowPriceInformation;
}

interface ISavedFlowKilometerFinderRaw extends ISavedFlowItemRaw {
  nextItemConfig?: ISavedFlowNextItemConfig[];
  pickupText: string;
  destinationText: string;
  placeSuggestionInfoText?: string;
  useOegkKilometer?: boolean;
}

interface ISavedFlowItemAnswerRaw {
  text: string;
  nextItemConfig?: ISavedFlowNextItemConfig[];
}

interface ISavedFlowItemHelpRaw {
  imageUrl: string;
  text: string;
}

interface ISavedFlowContactInformation {
  phoneNumber: string;
  name: string;
  text: string;
}

interface ISavedFlowPriceInformation {
  zoneIdentifier: string;
  text: string;
  textBelow?: string;
}

interface ISavedFlowNextItemConfig {
  idOfNext: number;
  districtFilter?: string;
}
