import "./InteractionContentContainer.css";
import * as React from "react";
import { Accordion, Badge, Card, useAccordionButton } from "react-bootstrap";

interface IInteractionContentContainerProps {
  id: number;
  heading: string;
  badgeText?: string;
  isOverflowVisible?: boolean;
  children: React.ReactNode;
}

interface ICustomToggleProps {
  id: string;
  eventKey: string;
  children: React.ReactNode;
}

function CustomToggle(props: ICustomToggleProps) {
  const onClick = useAccordionButton(props.eventKey);

  return (
    <Card.Title className="title-pointer" id={props.id} onClick={onClick}>
      {props.children}
    </Card.Title>
  );
}

export default class InteractionContentContainer extends React.Component<IInteractionContentContainerProps> {
  static collapseContainer = (id: number) => {
    const accordion = document.getElementById("inter-cc-col-" + id);

    if (accordion?.classList.contains("show")) {
      document.getElementById("inter-cc-col-toggle-" + id)?.click();
    }
  };

  static openContainer = (id: number) => {
    const accordion = document.getElementById("inter-cc-col-" + id);
    const element = document.getElementById("inter-cc-col-toggle-" + id);

    if (accordion && !accordion?.classList.contains("show")) {
      element?.click();
    }

    element?.closest(".accordion")?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  private getBadge() {
    if (this.props.badgeText) {
      let text = this.props.badgeText;

      let maxLength = 26;

      if (window.matchMedia("screen and (max-width: 768px)").matches) {
        maxLength = 7;
      }

      if (text.length > maxLength) {
        text = text.substr(0, maxLength) + "...";
      }

      return (
        <Badge className="larger-badge" bg="success" pill={true}>
          {text}
        </Badge>
      );
    }

    return null;
  }

  public render() {
    return (
      <Accordion defaultActiveKey={this.props.id.toString()}>
        <Card
          className={
            this.props.isOverflowVisible ? "my-2 overflow-visible" : "my-2"
          }
        >
          <Card.Body>
            <CustomToggle
              id={"inter-cc-col-toggle-" + this.props.id}
              eventKey={this.props.id.toString()}
            >
              <div className="d-flex px-1 justify-content-between">
                <div>{this.props.heading}</div>
                <div>{this.getBadge()}</div>
              </div>
            </CustomToggle>

            <Accordion.Collapse
              id={"inter-cc-col-" + this.props.id}
              eventKey={this.props.id.toString()}
              className="mt-4"
            >
              <div>{this.props.children}</div>
            </Accordion.Collapse>
          </Card.Body>
        </Card>
      </Accordion>
    );
  }
}
